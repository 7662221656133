import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import TextareaInput from '@/components/FormInputs/TextAreaInput/TextAreaInput.vue';
import PasswordInput from '@/components/FormInputs/PasswordInput/PasswordInput.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import LabelSelect from '@/components/FormInputs/LabelSelect/LabelSelect.vue';
import Sidebar from '@/components/Elements/Sidebar.vue';
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import Expander from '@/components/Expander/Expander.vue';
import MultiImageUpload from '@/components/MultiImageUpload/MultiImageUpload.vue';
import CheckboxInput from '@/components/FormInputs/CheckboxInput/CheckboxInput.vue';
// forms
import UpdateAvatar from '@/views/Profile/Forms/UpdateAvatar.vue';
let UpdatePassword = class UpdatePassword extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.form = {
            current_password: '',
            new_password: '',
            confirm_password: '',
        };
        this.errors = {
            current_password: [],
            new_password: [],
            confirm_password: [],
        };
        this.isValid = true;
    }
    async mounted() {
        if (this.profile) {
            this.loading = false;
        }
    }
    closeUpdate() {
        this.$emit('close');
    }
    // Submit form here
    async submit() {
        try {
            await this.$store.dispatch('user/updatePassword', this.form);
            this.$emit('submittedForm');
        }
        catch (e) {
            this.errors = e;
        }
    }
};
__decorate([
    Getter('getProfile', { namespace: 'user' })
], UpdatePassword.prototype, "profile", void 0);
UpdatePassword = __decorate([
    Component({
        components: {
            Btn,
            TextareaInput,
            PasswordInput,
            SelectInput,
            UpdateAvatar,
            LabelSelect,
            Sidebar,
            ProfileCard,
            Expander,
            MultiImageUpload,
            CheckboxInput,
        },
    })
], UpdatePassword);
export default UpdatePassword;
