import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ProjectService from '@/services/projects';
import UserService from '@/services/user';
// components
import Sidebar from '@/components/Elements/Sidebar.vue';
import Expander from '@/components/Expander/Expander.vue';
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import ProfileSubscription from '@/components/ProfileSubscription/ProfileSubscription.vue';
import LabelList from '@/components/Label/LabelList/LabelList.vue';
import Btn from '@/components/Button/Btn.vue';
import Gallery from '@/components/Gallery/Gallery.vue';
import UpdateProfile from '@/views/Profile/Forms/UpdateProfile.vue';
import UpdatePassword from '@/views/Profile/Forms/UpdatePassword.vue';
import SocialShare from '@/components/SocialShare/SocialShare.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let Profile = class Profile extends Vue {
    constructor() {
        super(...arguments);
        this.invoicesLoading = true;
        this.invoices = null;
        this.downloading = null;
        this.shareInfo = {
            url: `${process.env.VUE_APP_URL}profile`,
            title: this.$i18n.t('social.share_title'),
            description: 'Become a member of Africa Geographic',
            quote: 'Become a member of Africa Geographic',
            hashtags: `africa,africageographic,club`,
            twitterUser: 'africageo',
        };
        this.loading = false;
        this.verifyLoading = false;
        this.cancelling = null;
        this.isUpdating = false;
        this.isUpdatingPassword = false;
        this.coverImage = '';
        this.verifyEmail = {
            token: '',
            email: ''
        };
        this.errors = {
            token: [],
            email: [],
        };
    }
    onCoverImageChange() {
        this.coverImage = this.stateCoverImage;
    }
    onProfileUpdate() {
        this.setCoverImage();
        this.editSharingInfo();
        this.isUpdating = false;
        if (this.profile.email_change) {
            this.verifyEmail.email = this.profile.email_change.email;
        }
    }
    async mounted() {
        this.isUpdating = false;
        this.loading = true;
        try {
            const resp = await this.$store.dispatch('user/getUserProfile');
            await this.$store.dispatch('notification/showSuccess', resp);
            this.setCoverImage();
            this.editSharingInfo();
            this.loading = false;
            console.log(this.profile);
            if (this.profile.email_change) {
                this.verifyEmail.email = this.profile.email_change.email;
            }
        }
        catch (error) {
            console.log(`Profile`, error);
        }
        await this.$store.dispatch('engagements/setEngagementType', {
            engagementType: '',
            engagementSlug: '',
        });
        await this.userInvoices();
    }
    async logout() {
        const response = await this.$store.dispatch('auth/logout');
        if (response == true) {
            this.$router.push({ name: 'login' });
        }
    }
    setCoverImage() {
        this.coverImage = this.stateCoverImage;
        if (!this.coverImage && this.profile.images && this.profile.images.length > 0) {
            this.coverImage = this.profile.images[0].public_url;
        }
    }
    toggleUpdateProfile() {
        this.isUpdating = !this.isUpdating;
    }
    toggleChangePassword() {
        this.isUpdatingPassword = !this.isUpdatingPassword;
    }
    // dynamic user sharing info
    editSharingInfo() {
        this.shareInfo.title = `Africa Geographic - ${this.profile.name}`;
    }
    async userInvoices() {
        try {
            await UserService.getUserInvoices()
                .then((response) => {
                this.invoices = response.data.invoices;
                this.invoicesLoading = false;
            })
                .catch((e) => {
                this.$store.dispatch('notification/showError', e.response.data.message);
                this.invoicesLoading = false;
            });
        }
        catch (error) {
            this.$store.dispatch('notification/showError', 'We experienced an error loading your invoices, please try again in a few moments.');
        }
    }
    async submitVerifyEmail() {
        try {
            await UserService.verifyEmailChange(this.verifyEmail)
                .then((response) => {
                this.$store.dispatch('user/getUserProfile');
                console.log(response.message);
                this.$store.dispatch('notification/showSuccess', response.message);
                this.verifyLoading = false;
                this.verifyEmail = {
                    token: '',
                    email: ''
                };
                this.errors = {
                    token: [],
                    email: [],
                };
                if (this.profile.email_change) {
                    this.verifyEmail.email = this.profile.email_change.email;
                }
            })
                .catch((e) => {
                if (!e.response.data.data) {
                    this.errors.token = [
                        e.response.data.message
                    ];
                }
                else {
                    this.errors = e.response.data.data.messages;
                }
                this.$store.dispatch('notification/showError', e.response.data.message);
                this.verifyLoading = false;
            });
        }
        catch (error) {
            this.$store.dispatch('notification/showError', 'We experienced an error verifying your email address, please try again in a few moments.');
        }
    }
    async downloadInvoice(id) {
        try {
            this.downloading = id;
            await UserService.downloadInvoice(id)
                .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'AG_Invoice.pdf');
                document.body.appendChild(link);
                link.click();
                this.downloading = null;
            })
                .catch((e) => {
                this.$store.dispatch('notification/showError', e.response.data.message);
                this.downloading = null;
            });
        }
        catch (error) {
            this.$store.dispatch('notification/showError', 'We experienced an error loading your invoices, please try again in a few moments.');
        }
    }
    async cancelProjectSubscription(project_id) {
        this.cancelling = project_id;
        try {
            await ProjectService.cancelSubscription({
                project_id: project_id,
            })
                .then((response) => {
                this.$store.dispatch('user/getUserProfile');
                this.$store.dispatch('notification/showSuccess', 'Success! We have removed this project from your monthly donations.');
                this.cancelling = null;
            })
                .catch((e) => {
                this.$store.dispatch('notification/showError', e.response.message);
                this.cancelling = null;
            });
        }
        catch (error) {
            this.$store.dispatch('notification/showError', 'We experienced an error cancelling your subscription, please try again in a few moments.');
        }
    }
};
__decorate([
    Getter('getProfile', { namespace: 'user' })
], Profile.prototype, "profile", void 0);
__decorate([
    Getter('getCoverImage', { namespace: 'user' })
], Profile.prototype, "stateCoverImage", void 0);
__decorate([
    Watch('stateCoverImage')
], Profile.prototype, "onCoverImageChange", null);
__decorate([
    Watch('profile')
], Profile.prototype, "onProfileUpdate", null);
Profile = __decorate([
    Component({
        components: {
            Btn,
            Sidebar,
            LabelList,
            ProfileCard,
            ProfileSubscription,
            UpdateProfile,
            UpdatePassword,
            Expander,
            Gallery,
            SocialShare,
            CoverImage,
            MarkdownFormatter,
            TextInput
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | profile',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic profile',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/profile` }],
            };
        },
    })
], Profile);
export default Profile;
