import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import SubscriptionService from '@/services/subscriptions.ts';
let ProfileSubscription = class ProfileSubscription extends Vue {
    constructor() {
        super(...arguments);
        this.errors = '';
        this.loading = false;
    }
    async cancelSubscription() {
        this.loading = true;
        try {
            let subscription = await SubscriptionService.cancelSubscription();
            this.$store.dispatch('user/getUserProfile');
            this.loading = false;
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
            this.errors = e;
            this.loading = false;
        }
    }
    async resumeSubscription() {
        this.loading = true;
        try {
            let subscription = await SubscriptionService.resumeSubscription();
            this.$store.dispatch('user/getUserProfile');
            this.loading = false;
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
            this.errors = e;
            this.loading = false;
        }
    }
    async subscriptionCreated() {
        this.$store.dispatch('user/getUserProfile');
    }
};
__decorate([
    Prop()
], ProfileSubscription.prototype, "profile", void 0);
ProfileSubscription = __decorate([
    Component
], ProfileSubscription);
export default ProfileSubscription;
